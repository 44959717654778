import React from 'react'

import { CloudinaryImage } from 'ethos-design-system'

import styles from './InsurerComparison.module.scss'

const InsurerComparison = () => (
  <div className={styles.container}>
    <header>Compare Ethos to top insurers</header>
    <section>
      <CloudinaryImage
        alt=""
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/MassMutual_jjtlvc.png"
      />
      <CloudinaryImage
        alt=""
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/Prudential_yx6kvh.png"
      />
      <CloudinaryImage
        alt=""
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/NewYorkLife_zuqy7y.png"
      />
      <CloudinaryImage
        alt=""
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/Guardian_fdrkqe.png"
      />
      <CloudinaryImage
        alt=""
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/Northwestern_Mutual_qk3jqa.png"
      />
    </section>
    <footer>Rate comparison is only available for select products</footer>
  </div>
)

export default InsurerComparison
