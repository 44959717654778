import React from 'react'

import dynamic from 'next/dynamic'

import classnames from 'classnames'
import { Button, CloudinaryImage } from 'ethos-design-system'

import { useSingleCTAExperiment } from '@/hooks/features/useSingleCTAExperiment'
import { useTrustageLandingPage } from '@/hooks/features/useTrustageLandingPage'
import {
  TRUSTS_PAINTED_DOOR_V2_SEPARATE_FLOW,
  WILLS_PAINTED_DOOR_V2_SEPARATE_FLOW,
} from '@/hooks/features/useWillsFunnelFlow'
import { useWillsLPExperiment } from '@/hooks/features/useWillsLPExperiment'
import { useWillsLPV2_2Experiment } from '@/hooks/features/useWillsLPExperimentV2_2'

import Markdown from '../../global/Markdown'
import PriorityImage from '../../global/PriorityImage'
import styles from '../Trustworthy.module.scss'
import { SingleCTAInterface } from '../interface'
import InsurerComparison from './InsurerComparison'

const ButtonV2 = dynamic(
  () =>
    import('@getethos/ethos-design-system-v2').then((module) => module.Button),
  { ssr: false }
)
const IconV2 = dynamic(
  () =>
    import('@getethos/ethos-design-system-v2').then((module) => module.Icon),
  { ssr: false }
)

const Trustworthy = ({
  moduleData,
  ctaClick,
  policiesApproved,
}: {
  moduleData: SingleCTAInterface
  ctaClick: (flow?: string, label?: string) => void
  policiesApproved?: number
}) => {
  const {
    arrowIcon,
    ctaLabel,
    experimentKey,
    fiveMinutesHeading,
    heading,
    listType,
  } = moduleData

  const {
    isOnTreatment: isOnWillsTreatment,
    isTreatment2: isWillsLPTreatment2,
  } = useWillsLPExperiment()

  const { isPriceEnabled, isPriceVariant1, isPriceVariant2 } =
    useSingleCTAExperiment()
  const usingPriceVariant =
    experimentKey === 'singleCTA-price' && isPriceEnabled

  const { isTreatment } = useTrustageLandingPage()

  const headingToShow = isTreatment ? 'Instant <br/> Life insurance' : heading

  const { isOnTreatment: isOnWillsV2_2Treatment } = useWillsLPV2_2Experiment()

  const isWillsTreatment = isOnWillsV2_2Treatment || isOnWillsTreatment

  const policiesApprovedFormatted =
    policiesApproved?.toLocaleString('en-US') || '1,018'

  const defaultCardsInfo = [
    '**$2M** in coverage starts at **$2/day**',
    `**${policiesApprovedFormatted}** families approved today`,
    'No medical exams or blood tests',
  ]

  const willsCardsInfo = [
    'Built by lawyers, personalized by you',
    'Step-by-step guidance',
    '100% online, 30 day money back guarantee',
  ]

  const treatmentCardsInfo = [
    '**$500k** in coverage starts at **$1/day**',
    `**${policiesApprovedFormatted}** families approved today`,
    'No medical exams or blood tests',
  ]

  const priceVariantCardsInfo = [
    '**$2M** in coverage starts at **$2/day**',
    `**$100 billion** in coverage issued`,
    '**No medical exams** or blood tests',
  ]

  const WillsV2_2Buttons = () => {
    const createWillsLabel = 'CREATE YOUR WILL'
    const createTrustsLabel = 'CREATE YOUR TRUST'
    return (
      <div className={styles.willsCtaV2_2}>
        <ButtonV2
          buttonTitle={createWillsLabel}
          onClick={() =>
            ctaClick(WILLS_PAINTED_DOOR_V2_SEPARATE_FLOW, createWillsLabel)
          }
          size="md"
          variant="warningSolid"
        />
        <ButtonV2
          buttonTitle={createTrustsLabel}
          onClick={() =>
            ctaClick(TRUSTS_PAINTED_DOOR_V2_SEPARATE_FLOW, createTrustsLabel)
          }
          size="md"
          variant="warningSolid"
        />
      </div>
    )
  }

  let cardsInfo
  if (usingPriceVariant) cardsInfo = priceVariantCardsInfo
  else if (isWillsTreatment) cardsInfo = willsCardsInfo
  else if (isTreatment) cardsInfo = treatmentCardsInfo
  else cardsInfo = defaultCardsInfo

  const actionLabel =
    usingPriceVariant && isPriceVariant2 ? 'Compare rates' : ctaLabel

  const trustImages = isWillsTreatment
    ? features.SINGLE_CTA.willsTrustImages
    : features.SINGLE_CTA.trustImages

  const bgImage = isTreatment
    ? 'https://res.cloudinary.com/getethos/image/upload/v1732263928/AdobeStock_410597901_web_cutout_1_qzmrz2.png'
    : 'https://res.cloudinary.com/getethos/image/upload/v1706056286/a4a80674302dbb345546ec7ae210e8eb_trg43e.png'

  return (
    <div
      className={classnames(styles.trustworthy, {
        [styles.willsTrustworthy]: isWillsTreatment,
      })}
    >
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <h1
            className={classnames(styles.header, {
              [styles.fiveMinutesHeading]: fiveMinutesHeading,
              [styles.willsHeading]: isWillsTreatment,
            })}
          >
            <Markdown input={headingToShow || ''}></Markdown>
          </h1>
          {listType === 'checkmarks' ? (
            <div className="mb-6">
              {cardsInfo.map((card) => (
                <div
                  key={card}
                  className="mb-2 flex items-center gap-2 last:mb-0"
                >
                  <IconV2 name={'check'} sx={{ color: '#FFFFFF' }} />
                  <p className="body-s-reg text-white">{card}</p>
                </div>
              ))}
            </div>
          ) : (
            <div
              className={classnames(styles.bentoContainer, {
                [styles.willsContainer]: isWillsLPTreatment2,
              })}
            >
              {cardsInfo.map((card) => (
                <div key={card} className={styles.box}>
                  <div
                    className={classnames(styles.boxContent, {
                      [styles.willsBoxContent]: isWillsTreatment,
                    })}
                  >
                    <Markdown input={card} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {isWillsLPTreatment2 && (
            <div className={styles.affordablePlans}>
              <WillsPlanCheckmark />
              <span className={styles.planText}>
                Affordable plans starting at <b>$99</b>
              </span>
            </div>
          )}
          {isOnWillsTreatment ? (
            isOnWillsV2_2Treatment ? (
              <WillsV2_2Buttons />
            ) : (
              <div className={styles.willsCta}>
                <ButtonV2
                  buttonTitle={ctaLabel || ''}
                  leadingIcon="arrowForward"
                  onClick={() => ctaClick()}
                  size="lg"
                  variant="warningSolid"
                />
              </div>
            )
          ) : (
            <div className={styles.cta}>
              <Button.Unstyled
                fullWidth
                arrowIcon={!!arrowIcon}
                onClick={() => ctaClick()}
              >
                {actionLabel}
              </Button.Unstyled>
            </div>
          )}
          <div className={styles.trustImages}>
            {trustImages.map((trustImage, index) => (
              <div
                key={`${trustImage.title}_${index}`}
                className={styles.trustImageWrapper}
              >
                <div
                  key={`trustImage-${index}`}
                  className={classnames(styles.trustImageContainer, {
                    [styles.willsTrustImageConainter]: isWillsTreatment,
                  })}
                >
                  <CloudinaryImage
                    publicId={trustImage.imageUrl}
                    alt={trustImage.alt}
                    className={styles.trustImage}
                    crop={CloudinaryImage.CROP_METHODS.FIT}
                    height={[55, 55, 55, 55]}
                    width={[90, 90, 90, 90]}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {usingPriceVariant && isPriceVariant2 ? (
          <InsurerComparison />
        ) : (
          <div
            className={classnames(styles.bgImage, {
              [styles.tsBgImage]: isTreatment,
            })}
          >
            <PriorityImage
              publicId={bgImage}
              fetchpriority="high"
              alt="Father mother and child hugging"
              crop={CloudinaryImage.CROP_METHODS.FIT}
              height={[600, 768, 1440, 1920]}
              width={[300, 384, 729, 972]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const features = {
  SINGLE_CTA: {
    trustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1712744092/forbes_with_text_malcuy.svg',
        alt: 'Forbes',
        title: 'No. 1 instant life insurance',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1712744192/business_insider_with_text_ooheuy.svg',
        alt: 'Business Insider',
        title: `Best no medical exam <br/> term life policy`,
      },
    ],
    willsTrustImages: [
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1724067282/Frame_1000007064_yrt25p.svg',
        alt: '2 million families trust ethos',
        title: '2 million families trust ethos',
      },
      {
        imageUrl:
          'https://res.cloudinary.com/getethos/image/upload/v1724067282/Frame_1000007066_ua57ii.svg',
        alt: 'Best value for the money',
        title: 'Best value for the money',
      },
    ],
  },
}

const WillsPlanCheckmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M30.6668 15.9867L27.4135 12.2667L27.8668 7.34667L23.0535 6.25333L20.5335 2L16.0002 3.94667L11.4668 2L8.94683 6.25333L4.1335 7.33333L4.58683 12.2667L1.3335 15.9867L4.58683 19.7067L4.1335 24.64L8.94683 25.7333L11.4668 30L16.0002 28.04L20.5335 29.9867L23.0535 25.7333L27.8668 24.64L27.4135 19.72L30.6668 15.9867ZM25.4002 17.96L24.6535 18.8267L24.7602 19.96L25.0002 22.56L21.3468 23.3867L20.7602 24.3733L19.4402 26.6133L17.0668 25.5867L16.0002 25.1333L14.9468 25.5867L12.5735 26.6133L11.2535 24.3867L10.6668 23.4L7.0135 22.5733L7.2535 19.96L7.36016 18.8267L6.6135 17.96L4.8935 16L6.6135 14.0267L7.36016 13.16L7.24016 12.0133L7.00016 9.42667L10.6535 8.6L11.2402 7.61333L12.5602 5.37333L14.9335 6.4L16.0002 6.85333L17.0535 6.4L19.4268 5.37333L20.7468 7.61333L21.3335 8.6L24.9868 9.42667L24.7468 12.0267L24.6402 13.16L25.3868 14.0267L27.1068 15.9867L25.4002 17.96Z"
      fill="white"
    />
    <path
      d="M13.4535 18.3333L10.3602 15.2267L8.38683 17.2133L13.4535 22.2933L23.2402 12.48L21.2668 10.4933L13.4535 18.3333Z"
      fill="white"
    />
  </svg>
)

export default Trustworthy
